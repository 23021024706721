import React, {useState} from 'react'
import styled from '@emotion/styled'
import Button from '@material-ui/core/Button'
import PropTypes from 'prop-types'
import Links from '../Organisms/Links'

const BottomContainer = styled.div`
  height: 200px;
  position: fixed;
  bottom: 0;
`

const LinksContainer = styled.div`
  height: 100%;
  overflow: auto;
  background: white;
  margin: 5px;
`

const BottomLinks = ({ list }) => {
  const [isOpen, setOpen] = useState(false);
  return (
    <BottomContainer style={{height: isOpen ? "200px" : "75px"}}>
      <Button
        variant="contained"
        color="primary"
        size="small"
        onClick={(e) => setOpen(!isOpen)}
      >
        Links
      </Button>
      <LinksContainer >
        <Links list={list} />
      </LinksContainer>
    </BottomContainer>
  )
}

export default BottomLinks

BottomLinks.propTypes = {
  list: PropTypes.array
}