import React from 'react'
import { graphql, Link } from 'gatsby'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'

import Layout from '../../Templates/Layout'
import Content from '../../Templates/Content'
import Header from '../../Templates/Header'
import TagsBlock from '../../Organisms/TagsBlock'
import BottomLinks from '../../Organisms/BottomLinks'
import Links from '../../Organisms/Links'
import PostListItem from '../../Organisms/PostListItem'

const SuggestionBar = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  background: ${props => props.theme.colors.background};
  box-shadow: ${props => props.theme.shadows.post.default};
`
const PostSuggestion = styled.div`
  display: flex;
  align-items: center;
  margin: 1rem 3rem 0 3rem;
  a {
    color: ${props => props.theme.colors.text.base};
  }
`

const Post = ({ data, pageContext }) => {
  const { markdownRemark: post, allMarkdownRemark: relatedPosts} = data
  const { html, frontmatter, excerpt } = post
  const {
    created_at,
    updated_at,
    title,
    tags,
    cover,
    links,
    description,
    path
  } = frontmatter

  const image = cover ? cover.childImageSharp.fluid : {}
  const { next, prev } = pageContext
  const linkList = []
  for (let i = 0; i < links.length; i++) {
    const element = links[i];
    linkList.push(JSON.parse(element))
  }
  const seoProps = {
    title: title,
    desc: description || excerpt || ' ',
    image: image,
    pathname: path,
    article: true
  }
  return (
    <Layout seo={seoProps}>
      <Header title={title} created_at={created_at} updated_at={updated_at} cover={image} />
      <TagsBlock list={tags || []} />
      <Grid container  >
        <Grid item xs={12} md={2}>
        </Grid>
        <Grid item xs={12} md={8} style={{ padding: "5px" }}>
          <Content input={html} />
        </Grid>
        <Grid item xs={12} md={2}>
          <Hidden smDown>
            <Links list={linkList}/>
          </Hidden>
        </Grid>
      </Grid>
      <SuggestionBar>
        <PostSuggestion>
          {prev && (
            <Link to={prev.frontmatter.path}>
              Previous
              <h3>{prev.frontmatter.title}</h3>
            </Link>
          )}
        </PostSuggestion>
        <PostSuggestion>
          {next && (
            <Link to={next.frontmatter.path}>
              Next
              <h3>{next.frontmatter.title}</h3>
            </Link>
          )}
        </PostSuggestion>
      </SuggestionBar>
      {
        relatedPosts.edges.map((post, index) => {
          const {
            title,
            created_at,
            updated_at,
            tags,
            path,
            excerpt,
            cover
            } = post.node.frontmatter
          return (
            <PostListItem
              key={index}
              title={title}
              created_at={created_at}
              updated_at={updated_at}
              tags={tags}
              path={path}
              excerpt={excerpt}
              cover={cover ? cover.childImageSharp.fluid : {}}
          />
          )
        })
      }
      <Hidden smUp>
        {
          linkList.length > 0 && <BottomLinks list={linkList} />
        }
      </Hidden>
    </Layout>
  )
}

export default Post

Post.propTypes = {
  pageContext: PropTypes.shape({
    prev: PropTypes.object,
    next: PropTypes.object
  }).isRequired,
  data: PropTypes.object.isRequired
}

export const query = graphql`
  query($pathSlug: String!, $tags: [String!]) {
    markdownRemark(frontmatter: { path: { eq: $pathSlug } }) {
      html
      frontmatter {
        created_at(formatString: "YYYY年MM月DD日")
        updated_at(formatString: "YYYY年MM月DD日")
        path
        title
        tags
        cover {
          childImageSharp {
            fluid(
              maxWidth: 1920
              quality: 90
              duotone: { highlight: "#386eee", shadow: "#2323be", opacity: 60 }
            ) {
              ...GatsbyImageSharpFluid_withWebp
            }
            resize(width: 1200, quality: 90) {
              src
            }
          }
        }
        links
      }
    }
    allMarkdownRemark (
      sort: {order: ASC, fields: [frontmatter___created_at]},
      filter: {frontmatter: {tags: {in: $tags}, path: { ne: $pathSlug}}}
    ) {
      edges {
        node {
          frontmatter {
            draft
            path
            title
            created_at,
            updated_at,
            tags,
            cover {
              childImageSharp {
                fluid {
                  base64
                  tracedSVG
                  aspectRatio
                  src
                  srcSet
                  srcWebp
                  srcSetWebp
                  sizes
                  originalImg
                  originalName
                  presentationWidth
                  presentationHeight
                }
              }
            }
          }
        }
      }
    }
  }
`